import { Api, CookieService } from "./Services";
import { imagesFolder } from "./Api";

class Config {
	static gamesLoc = "/v1.0/games_engine/navigate.php";
	static imagesFolder = imagesFolder;
	static maxKids = 2;
	static SHOW_SHOP = 1;
	static async loadConfig() {
		let res = await Api.getConfig();

		if (res.config) {
			let langId = CookieService.getCookie("info[lang_id]");
			if (!langId) {
				CookieService.setCookie("info[lang_id]", res.config.default_lang);
			}
			this.langId = langId || res.config.default_lang;

			for (let [key, value] of Object.entries(res.config)) {
				this[key] = value;
			}
		}
	}

}


export default Config;