import React,{useState,useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import { MustAdminPass,compose } from '../Hocs/Hocs';
import {Api} from "./../../services/Services";
import {BaseBtn, BaseWrap} from "./../utils/Utils";
import AdminMenu from "./AdminMenu";


const langCodes="ar,cn,en,he,kr,ro,ru,tr".split(",");

const LangUpd=()=>{
	const [areas,areasUpd]=useState([]);
	const [area,areaUpd]=useState("");
	const [terms,termsUpd]=useState({});
	const [curLangs,curLangsUpd]=useState(["en","he"]);
	
	
	
	useEffect(()=>{
		const load=async ()=>{
			let res=await Api.getLangAreas();
			if(res.areas){
				areasUpd(res.areas);
			}
		}
		load();
	},[]);

	useEffect(()=>{
		termsUpd({});
		if(area){
			const load=async ()=>{
				let res=await Api.getLangTermsByArea(area);
				if(res.terms){
					termsUpd(res.terms);
				}
			} 
			load();
		}
	},[area]);

	const chLang=(pos,lang)=>{
		let r=[...curLangs];
		r[pos]=lang;
		curLangsUpd(r)
	}

	const blurTerm=(term,langId)=>{
		let sendObj={term,chLangId:langId,value:terms[term][langId],area};
		console.log(sendObj);
		Api.updLangTerm(sendObj);

	}
	const updTerm=(term,langId,value)=>{
		let nextTerms={...terms};
		nextTerms[term][langId]=value;
		termsUpd(nextTerms);
	}	

	return (
		<BaseWrap items={<AdminMenu  />}  >
			<div style={{width:"100%"}}>
			
				<h1>Lang</h1>
				
					{area?
					<>
					<h1>{area}</h1>
					<button onClick={()=>areaUpd("")} className="btn"  >Back to areas</button>
					<table border="1"  style={{marginBottom:20,marginTop:20}} >
						<thead>
							<tr>
								<th>Term</th>
								{[0,1].map(num=><th key={num}>
									<select style={{minWidth:100}} onChange={e=>chLang(num,e.target.value)} value={curLangs[num]} >
										{langCodes.map(langCode=><option key={langCode} value={langCode} >{langCode}</option>)}
									</select>
								</th>)}
								
							</tr>
						</thead>
						<tbody>
							{Object.keys(terms).map(term=>
								<tr key={term} >
									<td>{term}</td>
									{[0,1].map(num=><td key={num}>
										{curLangs[num]}
										<textarea style={{width:"100%"}} onChange={e=>updTerm(term,curLangs[num],e.target.value)} onBlur={e=>blurTerm(term,curLangs[num])} value={terms[term][curLangs[num]] || ""} ></textarea>

									</td>)}
								</tr>
							)}
						</tbody>
					</table>
					<div className="regi-btns">
						<BaseBtn label="Done" />
					</div>
					</>
					:
					<div style={{textAlign:"start"}}>
					{areas.map(ar=><div key={ar} className="pointer" onClick={()=>areaUpd(ar)} >{ar}</div>)}
					</div>
					}				
				
			</div>
		</BaseWrap>
	);
}


export default compose(MustAdminPass,LangUpd);