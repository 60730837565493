import React,{useContext,useEffect, useState} from 'react';
import {Link,useHistory} from "react-router-dom";
import AppContext from "../../AppContext";
import {Config, Lang, ModalService} from "../../services/Services";
import LoginScreen from '../user/LoginScreen';
import {LoadProds,MustUser,compose} from '../Hocs/Hocs';
import ProductRow from "./ProductRow";

const Cart=()=>{
	const context=useContext(AppContext);
	const [reload,reloadUpd] = useState(1);

	const history = useHistory();
	
	const products=[...context.StoreService.getProductsInCart()];
	const cost=context.StoreService.getCostByIds(products.map(it=>it.id));	
	
	const toCheckout=()=>{
		if(context.UserService.user){
			history.push("/checkout");
		}
		else{
			context.UserService.opLoginScreen(false);
		}
	}
	const addRemoveProduct=()=>{
		reloadUpd(reload+1);
	}
	
	return(
		<section className="cart pad">
			<div className="top-wrap">
				<div className="top-area-with-btn">
					<Link to="/products/0" >
						<span className="back-btn"></span>
					</Link>
					<div className="texts" >
						<h4>{Lang.productsCatalog.shopping_cart}</h4>
						<p  >{Lang.productsCatalog.shopping_cart_games} {products.length}</p>
					</div>
				</div>
			</div>

			{products.map(prod => <ProductRow key={prod.id} product={prod} addRemove={() => addRemoveProduct(prod)} isCartView />)}

			{/* <div className="filter-icon"></div> */}
			<div className="above-footer non-app" >
				<p>{Lang.productsCatalog.cart_bottom_text}</p>
			</div>
			<div className="footer">
				<div className="cart payment" onClick={toCheckout}></div>
				<p onClick={toCheckout}>{Lang.productsCatalog.shopping_cart_total} <span className="sum"  dangerouslySetInnerHTML={{__html:Config.DEFAULT_CURRENCY_SYMBOL+""+cost}} ></span> &gt; </p>
			</div>
		</section>
	)
}

export default compose(MustUser,LoadProds,Cart);