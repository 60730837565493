import React from 'react';
import {Route } from "react-router-dom";
import StaticPage from "./components/StaticPage";
import Charts from "./components/Charts";
import Home from "./components/Home";
import Store from "./components/store/Store";
import ProductPage from "./components/store/ProductPage";
import AdminLogin from "./components/admin/AdminLogin";
import CrCoupons from "./components/admin/CrCoupons";
import CrActivationCodes from "./components/admin/CrActivationCodes";
import Invoices from "./components/admin/Invoices";
import Settings from './components/user/Settings';
import Regi from './components/user/Regi';
import Cart from './components/store/Cart';
import Checkout from './components/store/Checkout';
import LangUpd from './components/admin/LangUpd';
import UpdPackages from './components/admin/UpdPackages';


const Routes=()=>{

	return(
		<>
			<Route exact path="/" render={props => (
				<Home  />
			)} />

			<Route exact path="/products" component={Store} />
			<Route path="/products/:filt" component={Store} />
			<Route path="/charts" component={Charts} />
			<Route path="/regi" component={Regi} />
			<Route path="/settings" component={Settings} />
			<Route path="/checkout" component={Checkout} />
			<Route path="/cart" component={Cart} />
			<Route path="/pages/:page" component={StaticPage} />
			<Route path="/product/:productId" component={ProductPage} />

			<Route  path="/admin/login" component={AdminLogin} />
			<Route  path="/admin/cr-coupons" component={CrCoupons} />
			<Route path="/admin/cr-activation-codes" component={CrActivationCodes} />
			<Route path="/admin/invoices" component={Invoices} />
			<Route path="/admin/upd-packages" component={UpdPackages} />
			<Route path="/admin/lang" component={LangUpd} />

			<Route  exact path="/games" render={props =>(
				<div>Games..</div>
			)} />
		
		
		</>

	)
}

export default Routes;
