import React,{useContext,useState} from 'react';
import AppContext from "../../AppContext";
import {Link} from "react-router-dom";
import {FormService,Api,ModalService,Lang} from "./../../services/Services";
import {BaseSubmit,InputDiv, RegiBtns} from "./../utils/Utils";
import Regi from './Regi';
import { GenForm } from '../Hocs/Hocs';

const fields=[
	{name:"code",label:"Code",validation:{notEmpty:true,activationCode:true},toUpperCase:true,placeholderLang:["activate_login","label_activation_key"]},
	{name:"check",type:"checkbox",validation:{checked:true},labelLang:["activate_login","label_accept_terms"]},
];
const fieldsObj={};
const apiFunc=async()=>await Api.activationCode(fieldsObj.code[0]);
	
const Activation=({errors,submit,servError})=>{	//
	const context=useContext(AppContext);

	fieldsObj.code=useState("");
	fieldsObj.check=useState(true);
	FormService.fieldsHoist(fields,fieldsObj);
	
	const submitFunc=async ()=>{
		if(await submit()){
			context.UserService.activationCode=fieldsObj.code[0];
			ModalService.openBlueCover(<Regi />);
		}
	}
	
	

	if(context.UserService.user){return null;}
	
	return (
		<>
		<ul className="form" >
			<li >
				<InputDiv   fieldObj={fields[0]}  error={errors[fields[0].name]}  />
				<p  >{Lang.activate_login.activation_key_loc}</p>
			</li>
			<li ><InputDiv   fieldObj={fields[1]}  error={errors[fields[1].name]} postLabelContent={<p><Link onClick={()=>ModalService.closeBlueCover()} to="/pages/terms-of-use"  className="underline" >{Lang.activate_login.goto_term_of_use}</Link></p>}  /></li>
		</ul>
		<RegiBtns servError={servError} >
			<BaseSubmit onClick={submitFunc} label={Lang.activate_login.butten_register}  />
		</RegiBtns>
		</>
	);
}

export default GenForm(Activation,{fields,apiFunc,langFail:["errorTexts","activation_fail"],langSuc:["errorTexts","activation_suc"]});
