import React from "react";
import { Subject } from 'rxjs';
import LoginScreen from '../components/user/LoginScreen';
import {Api,CookieService,ModalService,Config} from './Services';


class UserService{
	isGoToPlay=false;
	curAdminLogin="";
	userChangedSubject=new Subject();	
	
	activationCode="";
	curPlayerId=null;
	user=null;

	opLoginScreen(isGoToPlay){
		this.isGoToPlay=isGoToPlay;
		console.log(this.isGoToPlay);
		ModalService.openBlueCover(<LoginScreen />);
	}
	goToGames(){
		window.location=Config.gamesLoc;
	}
	appendGamesToUser(newIds){
		console.log(newIds);
		this.user.gameIds=[...this.user.gameIds,...newIds];
		console.log(this.user.gameIds);
	}
	
	logout(){
		
		this.user=null;
		this.deleteAccountAndPlayerCookies();
		this.userChangedSubject.next(false);
	}
	updateInfo(obj){
		let doUpd=false;
		if(obj.user!==undefined){
			doUpd=true;
			this.user=obj.user;
		}
		if(obj.curPlayerId!==undefined){
			doUpd=true;
			this.curPlayerId=obj.curPlayerId;
		}
		this.setAccountAndPlayerCookies();
		if(doUpd){
			this.userChangedSubject.next(true);
		}
	}
	updatePlayer(newId){
		this.curPlayerId=newId;
		this.setAccountAndPlayerCookies();
	}
	getAccountAndPlayer(){
		return [
			(this.user?this.user.id:""),
			this.curPlayerId
		]
	}
	setAccountAndPlayerCookies(){
		if(this.user){
			CookieService.setCookie("accountId",this.user.id);
		}
		else{
			CookieService.setCookie("accountId",this.user.id,new Date(0));
		}
		if(this.curPlayerId){
			CookieService.setCookie("playerId",this.curPlayerId);
		}
		else{
			CookieService.setCookie("playerId",this.curPlayerId,new Date(0));
		}
	}
	deleteAccountAndPlayerCookies(){
		CookieService.deleteCookie("accountId");
		CookieService.deleteCookie("playerId");
	}
	async checkAccountAndPlayerCookies(){
		let playerId=CookieService.getCookie("playerId");
		if(playerId){
			this.curPlayerId=playerId;
		}
		let accountId=CookieService.getCookie("accountId");
		if(accountId){
			let res=await Api.getAccountObject(accountId);
			if(res.account){
				this.updateInfo({user:res.account});
				return true;
			}
		}
		return false;
	}
	
	updUserInfo(obj){
		Object.keys(obj).forEach(k=>this.user[k]=obj[k]);
	}
}



export default UserService;