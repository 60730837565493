import React, { useContext, useState, useEffect } from 'react';
import { useHistory, useParams, Link } from "react-router-dom";
import AppContext from "../../AppContext";
import ProductRow from "./ProductRow";
import { Lang } from "../../services/Services";
import Filter from "../Filter";

const empty=()=>{};

const Store = () => {
	const context = useContext(AppContext);
	const history = useHistory();
	const StoreService = context.StoreService;
	const { filt } = useParams();
	const [filter, filterUpd] = useState(filt);
	const [products, productsUpd] = useState([]);
	const [filterOpen,filterOpenUpd]=useState(false);
	const productsNum=[...context.StoreService.productIdsInCart].length;
	const user =context.UserService?.user;

	const filters = [
		{ id: 0, text: Lang.productsCatalog.tab_name_a },
		{ id: 1, text: Lang.productsCatalog.tab_name_1 },
		{ id: 2, text: Lang.productsCatalog.tab_name_2 },
		{ id: 3, text: Lang.productsCatalog.tab_name_3 },
		{ id: 4, text: Lang.productsCatalog.tab_name_4 },
		// { id: -1, text: "Cart" }
	]

	// const resetCart = () => {
	// 	StoreService.resetCart();
	// 	productsUpd([...products]);
	// }
	const chFilter = filter => {
		console.log("ch", filter);
		let products = [];
		if (filter === -1) {
			products = StoreService.getProductsInCart();
		}
		else {
			products = StoreService.getProductsByFilter(filter);
		}
		filterUpd(filter);
		productsUpd([...products]);
	}
	useEffect(() => {
		const load = async () => {
			await StoreService.getProductsFromApi();
			chFilter(+filter || 0);
		}
		load();
	}, [])
	useEffect(() => {
		let f = filt || 0;
		console.log(33, f);
		chFilter(+f);
	}, [filt]);

	const clickChFilter = filter => {
		history.push("/products/" + filter.id);
		filterOpenUpd(false);
		// chFilter(filter);
	}
	const addRemoveProduct = () => {
		productsUpd([...products]);
	}
	const toCart=()=>{
		history.push("/cart");
	}

	if(filterOpen){
		return <Filter closeFilter={()=>filterOpenUpd(false)} opts={filters} chosen={filter} chOpt={clickChFilter} title={Lang.productsCatalog.filter_title}   />;		
	}
	return (
		<section className="store pad">
			{/* <div className="top-area">
				{Lang.productsCatalog.support_text} 
				<Link style={{margin:"0 4px"}} to="/pages/contact-us"><button className="link" >{Lang.productsCatalog.support_button}</button></Link>
			</div> */}

			{products.map(prod => <ProductRow key={prod.id} product={prod} addRemove={(e) => addRemoveProduct(prod, e)} />)}

			<div className="filter-icon" onClick={()=>filterOpenUpd(true)} ></div>
			<div className="footer" >
				<div className="cart" onClick={user?toCart:empty} >
					<div className="cart-number">{user?productsNum:0}</div>
				</div>
				<p onClick={user?toCart:empty}>{Lang.productsCatalog.to_cart} &gt;</p>
			</div>
		</section>		
	)

}

export default Store;
