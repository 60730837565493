import React, { useContext, useEffect, useState } from 'react';
import AppContext from "../../AppContext";
import { Link, useHistory } from "react-router-dom";
import { ModalService,Lang, Config } from "../../services/Services";
import Settings from "../user/Settings";
import LoginScreen from "../user/LoginScreen";
import IconSelect from '../user/IconSelect';

const UserMenu = () => {
	const context = useContext(AppContext);
	const history=useHistory();
	
	const [user, userUpd] = useState(context.UserService.user);
	const [isOpenKids, isOpenKidsUpd] = useState(false);
	useEffect(() => {
		const obser = context.UserService.userChangedSubject.subscribe(() => {
			userUpd(context.UserService.user);
		});
		return () => { obser.unsubscribe(); }
	}, []);

	const child = (user && user.kids && user.kids.find(child => child.id === context.UserService.curPlayerId));

	const selectChild=id=>{
		context.UserService.updatePlayer(id);
	}

	const play=()=>{
		if(context.UserService.user){
			context.UserService.goToGames();
		}
		else{
			context.UserService.opLoginScreen(true);
		}
	}
	const logout=()=>{
		ModalService.closeBlueCover();
		context.UserService.logout()
		history.push("/");
	}
	const loginClick=()=>{
		context.UserService.opLoginScreen(false);

	}

	return (
		<>			
			<div className="menu user-links">
				<ul >
					<li >
						<img alt="" src="/assets/images/user.png" className="only-lg" />
						<span>{Lang.personal_info.welcome} {child?.name || Lang.personal_info.guest}</span>
					</li>
					<li>		
						<span onClick={()=>play()} >{Lang.personal_info.play}</span>
					</li>
					{user?
					<>
						<li><Link to="/charts" >{Lang.personal_info.accomplishments}   </Link></li>
						<li><span   onClick={()=>ModalService.openBlueCover(<Settings />)} >{Lang.personal_info.my_account}  </span></li>
						<li><span onClick={()=>logout()}>{Lang.personal_info.logout}  </span></li>
					</>
					:
					<li><span onClick={() => loginClick()}>{Lang.personal_info.login}</span></li>
					}
				</ul>
			</div>


			{user &&
			<div className="menu children">
				<button onClick={()=>isOpenKidsUpd(!isOpenKids)} >
					{/* <img src="/assets/images/icon-drop.png" alt="" /> */}
					<IconSelect gender={child.gender} val={child.icon} imgOnly />
					<span>{child.name}  </span>
					<i className="fa fa-angle-down"></i>
				</button>
				{isOpenKids &&
				<div className="dropdown">
					<ul  onClick={()=>isOpenKidsUpd(false)} >
						{user.kids.map(row=><li key={row.id} onClick={()=>selectChild(row.id)}  >
							<IconSelect gender={row.gender} val={row.icon} imgOnly />
							<span className="marg-bef" >{row.name}</span>
						</li>)}   							
					</ul>
				</div>
				}
			</div>
			}
		</>
	)
}

export default UserMenu;